namespace $.$$ {
	
	$mol_style_define( $hyoo_lupa, {
		
		overflow: 'hidden',
		
		Camera: {
			objectFit: 'cover',
			transform: 'scale(2) translateY(-25%)',
			alignSelf: 'stretch',
			justifySelf: 'stretch',
		},
		
	} )
	
}
